<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>Profile</strong>
            </div>
            <b-row v-if="user.isAdmin || user.isBuildingUser">
              <b-col sm="12">
                <form-group
                  :validator="$v.form.fullName"
                  label="Full Name"
                  :use-horizontal="false"
                >
                  <b-form-input
                    type="text"
                    id="fullName"
                    placeholder="Enter your full name"
                    autocomplete="off"
                    v-model="form.fullName"
                  ></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.email"
                  label="Email"
                  :use-horizontal="false"
                >
                  <b-form-input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    autocomplete="off"
                    v-model="form.email"
                    disabled
                  ></b-form-input>
                </form-group>
              </b-col>
            </b-row>
            <b-row v-else-if="user.role.id == 1">
              <b-col sm="12">
                <form-group
                  :validator="$v.form.name"
                  label="Name"
                  :use-horizontal="false"
                >
                  <b-form-input
                    type="text"
                    placeholder="Enter name"
                    autocomplete="off"
                    v-model="form.name"
                    disabled
                  ></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.uen"
                  label="UEN"
                  :use-horizontal="false"
                >
                  <b-form-input
                    type="text"
                    placeholder="Enter UEN"
                    autocomplete="off"
                    v-model="form.uen"
                    disabled
                  ></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.meters"
                  label="Meters"
                  :use-horizontal="false"
                >
                  <b-textarea
                    placeholder="Enter Meters"
                    autocomplete="off"
                    v-model="form.meters"
                    disabled
                  ></b-textarea>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.address"
                  label="Billing Address"
                  :use-horizontal="false"
                >
                  <b-textarea
                    placeholder="Enter billing address"
                    v-model="form.address"
                  ></b-textarea>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.billing"
                  label="Billing Unit#"
                  :use-horizontal="false"
                >
                  <b-form-input
                    type="text"
                    placeholder="Enter billing unit#"
                    autocomplete="off"
                    v-model="form.unit"
                  ></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.postalCode"
                  label="Billing Postal Code"
                  :use-horizontal="false"
                >
                  <b-form-input
                    type="text"
                    placeholder="Enter billing postal code"
                    autocomplete="off"
                    :maxlength="6"
                    v-model="form.postalCode"
                  ></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.accountId"
                  label="Account#"
                  :use-horizontal="false"
                >
                  <b-form-input
                    type="text"
                    placeholder="Enter account#"
                    autocomplete="off"
                    v-model="form.accountId"
                    disabled
                  ></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.deposit"
                  label="Deposit (SGD)"
                  :use-horizontal="false"
                >
                  <vue-numeric-input
                    v-model="form.deposit"
                    :step="2"
                    :controls="false"
                    className="form-control"
                    width="100%"
                    disabled
                  ></vue-numeric-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group label="Email" :use-horizontal="false">
                  <b-form-tags
                    placeholder="Enter email"
                    v-model="form.emails"
                    separator=";"
                  ></b-form-tags>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group label="Contact Details" :use-horizontal="false">
                  <b-textarea
                    placeholder="Enter contact details"
                    v-model="form.contactDetails"
                  >
                  </b-textarea>
                </form-group>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button
                type="submit"
                size="sm"
                variant="success"
                class="float-right"
              >
                Save Changes
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  data: () => ({
    form: {
      fullName: null,
      email: null,

      name: null,
      uen: null,
      meters: null,
      address: null,
      unit: null,
      postalCode: null,
      accountId: null,
      deposit: null,
      emails: null,
      contactDetails: null,
    },
    originalTenant: {},
  }),
  validations() {
    const self = this;
    if (self.user.role.id == 0) {
      return {
        form: {
          fullName: { required },
          email: { required },
        },
      };
    } else {
      return {
        form: {
          name: { required },
          uen: { required },
          meters: { required },
          address: { required },
          unit: { required },
          postalCode: {
            required,
            numeric,
            minLength: minLength(6),
            maxLength: maxLength(6),
          },
          accountId: { required },
          deposit: { required },
        },
      };
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {
    const self = this;
    if (self.user.isAdmin || self.user.isBuildingUser) {
      self.form.fullName = self.user.fullName;
      self.form.email = self.user.email;
    } else if (self.user.role.id == 1) {
      self.getTenant();
    }
  },
  methods: {
    getTenant() {
      const self = this;

      let loader = self.$loading.show();
      self.$store
        .dispatch("apis/get", {
          url: `/tenant/profile`,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.originalTenant = response.data;
            self.form = {
              name: response.data.name,
              uen: response.data.uen,
              meters: response.data.meters
                .map(
                  (x) => `${x.units.join(",")} (Meter Serial #${x.serialNo})`
                )
                .join(","),
              address: response.data.address,
              unit: response.data.unit,
              postalCode: response.data.postalCode,
              accountId: response.data.accountId,
              deposit: response.data.deposit,
              emails: response.data.emails,
              contactDetails: response.data.contactDetails,
              autoSend: response.data.autoSend,
            };
          }
          loader.hide();
        });
    },
    handleSubmit() {
      const self = this;

      self.$v.form.$touch();
      if (self.$v.form.$pending || self.$v.form.$error) return;

      let _confirmText = "You are about to update your profile. Are you sure ?",
        _okText = "Yes, Update",
        _action = "apis/put",
        _url =
          self.user.role.id == 0 ? `/user/${self.user.id}` : `/tenant/${self.originalTenant.accountId}`,
        _form = {};

      if (self.user.role.id == 0) {
        _form = {
          fullName: self.form.fullName,
          email: self.form.email,
          role: self.user.role.id,
          actived: self.user.actived,
        };
      } else if (self.user.role.id == 1) {
        _form = {
          name: self.originalTenant.name,
          uen: self.originalTenant.uen,
          meters: self.originalTenant.meters.map((x) => x.id),
          address: self.form.address,
          unit: self.form.unit,
          postalCode: self.form.postalCode,
          accountId: self.originalTenant.accountId,
          deposit: self.originalTenant.deposit,
          emails: self.originalTenant.emails,
          contactDetails: self.originalTenant.contactDetails,
          autoSend: self.originalTenant.autoSend,
        };
      }

      self.$dialog
        .confirm(_confirmText, {
          okText: _okText,
          cancelText: "Cancel",
          loader: true,
        })
        .then((dialog) => {
          self.$store
            .dispatch(_action, {
              url: _url,
              params: _form,
            })
            .then((response) => {
              dialog.close();
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.message,
                });
              } else {
                self.$message.success({
                  zIndex: 10000,
                  message: response.message,
                });

                self.user.fullName =
                  self.user.role.id == 0 ? self.form.fullName : self.form.name;
                self.$store.dispatch("auth/updateUser", self.user);
              }
            });
        });
    },
  },
};
</script>
